// InventoryContainer.jsx
import React, { createRef, useEffect, useRef, useState } from 'react';
import styles from './CustomTags.module.css';
import { CustomTagItem } from '../custom-tag-item/Custom-tag-item';


const CustomTags = ({ tags, tagsValuesDefault, customTagsValues, calculationType, onFormValueChange }) => {
    const defaultTagsValues = tagsValuesDefault[calculationType];
    const getTagValue = (tagname) => {
        for (const key in defaultTagsValues) {
            const strippedKey = key.replace('|{', '').replace('}', '');
            if (strippedKey === tagname) {
                return defaultTagsValues[key];
            }
        }
        return '';
    };

    return (
        <div style={{ margin:'10px', marginTop: '100px' }}>
            <div style={{borderBottom:'1px solid #ccc'}}>
                <h1>CustomTags</h1>
            </div>
            <div>
                {tags.map((tag, index) => (
                    (tag.isEditable && getTagValue(tag.tagName)) && <CustomTagItem key={index}
                        tag={tag}
                        defaultValue={getTagValue(tag.tagName)}
                        customValue={customTagsValues?.[tag.tagName] ?? getTagValue(tag.tagName)}
                        onFormValueChange={onFormValueChange}
                        calculationType={calculationType}
                    />
                ))}
            </div>
        </div>
    );
};

export default CustomTags;