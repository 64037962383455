import React from 'react';
import styles from './TruckloadsView.module.css';

const TruckloadsView = ({ Truckloads, Truckloads_to_move, Total_cubes_Within_Building, scrollRefs, highLightItem }) => {
    var truckloads = (Number(Total_cubes_Within_Building) > 0) ? Truckloads_to_move : Truckloads;
    return (
        <>
            <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total Truckloads:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}><span className={`${(highLightItem === 'truckloads') ? styles.highLighted : ""}`} ref={scrollRefs?.current['truckloads']}>{truckloads}</span></p>
            </li>
            {
                (truckloads > 1 && (Math.floor(Number(truckloads))) - Number(truckloads) < 0) ?
                    <li className={styles.paramsItem}>
                        <div><span>{Math.floor(truckloads)}</span> truck(s) <span className={`${(highLightItem === 'trucksPercent') ? styles.highLighted : ""}`} ref={scrollRefs?.current['trucksPercent']}>{Math.floor(Number((Number(truckloads) - Math.floor(truckloads)).toFixed(2) * 100))}%</span></div>
                    </li>
                    : ''
            }
        </>
    );
};

export default TruckloadsView;