import React, { useState, useContext, useCallback, useEffect } from 'react';
import useCollapse from 'react-collapsed'
import { SocketContext } from '../../context/socket';
// Calc util functions
import { generateNotIncludedServicesText, generateUnpackingText, getAdditionalServicesResText, getChargesResText, getCheckMarkLabelById, getCurrentRatePercent, getFormattedRateInfoTwoDays, getFormattedRateInfoTwoDaysDay2, getFormattedRateLoadInfoTwoDays, getHigherNumberOfHours, getLowerNumberOfHours, getTagsValues, getTotalTimeRange, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
// Styles

import styles from './CalculationThreeDays150Params.module.css';
//Components
import { CalculationSeparateDayView } from '../calculation-separate-day-view/Calculation-separate-day-view';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';
import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results';
import { AdditionalServicesResults } from '../additional-services-results/Additional-services-results';
import { setTWOTripsOrOK_Day2, setTotalFragileBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { useDispatch } from 'react-redux';
import { AdditionalServicesEstimateInfo } from '../additional-services-estimate-info/Additional-services-estimate-info';
import TruckloadsView from '../truckloads/TruckloadsView';

export const CalculationThreeDays150Params = ({
  commonValues,
  department,
  threeDays150Miles,
  selectedMiscCheckMarks,
  checkboxValueChange,
  editorState,
  calculationData,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  setShowGenerateEmail, furniture, roomsAreas,
  scrollRefs,
  highLightItem, setTagsValuesDefault, customTagsValues, showCustomTags
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const [Total_cubes, setTotal_cubes] = useState(0);

  const isCuFtDepartment = department?.type === 'cuft';

  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'threeDays150Miles', commonValues: commonValues, threeDays150Miles: threeDays150Miles, department: department })

    socket.on('Total_cubes', (data) => { setTotal_cubes(data) });

  }, [commonValues, threeDays150Miles, department])

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {

        }, furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      if (typeof setTagsValuesDefault === 'function') {
        setTagsValuesDefault(prevState => ({
          ...prevState,
          [calculationData.calculationType]: tagValuesCopy
        }));
      }
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          tagValues[`|{${key}}`] = customTagsValues[key];
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown, tags, /* calculationData,  */showCustomTags]);

  /*  const handleResCopy = (evt) => {
     evt.preventDefault();
     var truckloads = (Number(Total_cubes_Within_Building) > 0) ? Truckloads_to_move : Truckloads;
 
     let unpackingText = generateUnpackingText(commonValues, department, isCuFtDepartment, Movers_Unpacking_Separate_Day, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Truck_fee_Unpacking_Separate_Day, Trucks_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, UnPacking_Charge, Total_Cash_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day, threeDays150Miles, Flat_rate_Unpacking_Separate_Day_cash, Flat_rate_Unpacking_Separate_Day)
 
     let chargesText = getChargesResText(department, threeDays150Miles.selectedChargesNamesDay1, Trucks_To_Pack_Day1);
 
     let additionalServicesText = getAdditionalServicesResText(department, commonValues.selectedAdditionalServices, Trucks_Day2, commonValues.additionalServicesPlaceType, Total_cubes);
 
 
     let chargesTextDay2 = getChargesResText(department, threeDays150Miles.selectedChargesNamesDay2, Trucks_Day2);
 
 
 
     let proceduresText = '';
     let procedureAdded = false;
     commonValues.procedures.forEach((procedure, pkIndex) => {
       if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && threeDays150Miles.proceduresOnDay1) {
         if (!procedureAdded) {
           proceduresText += 'Procedures:\n';
           procedureAdded = true;
         }
         proceduresText += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
         if (Object.keys(department).length !== 0) {
           department.packing.cratings.forEach((crating, index) => {
             if (procedure.procedureType === crating[`crateName${index}`]) {
               proceduresText += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
             }
           });
         }
         proceduresText += '\n';
       }
     });
 
     let proceduresTextDay2 = '';
 
     commonValues.procedures.forEach((procedure, pkIndex) => {
       if (procedure.procedureType !== '' && procedure.procedureType !== 'None' && threeDays150Miles.proceduresOnDay2) {
         proceduresTextDay2 += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
         if (Object.keys(department).length !== 0) {
           department.packing.cratings.forEach((crating, index) => {
             if (procedure.procedureType === crating[`crateName${index}`]) {
               proceduresTextDay2 += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
             }
           });
         }
         proceduresTextDay2 += '\n';
       }
     });
 
     let notIncludedServicesText = generateNotIncludedServicesText(department, Total_cubes, commonValues)
 
 
     const invoiceText = `Total Cubic ft: ${parseFloat(Total_cubes).toFixed(2)} cu ft.
 ${(Total_cubes_Within_Building > 0) ? `Cubic ft within building: ${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
 Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.` : ''}
 Total Truckloads: ${truckloads}${(truckloads > 1 && (Math.floor(Number(truckloads))) - Number(truckloads) < 0) ? Math.floor(truckloads) + ' truck(s) + ' + Math.floor(Number((Number(truckloads) - Math.floor(truckloads)).toFixed(2) * 100)) + "%" : ''}
 ${(isCuFtDepartment) ? `Day 1 - Round trip miles: ${parseFloat(commonValues.milesFromParkingCuFt * 2).toFixed(1)}
 Total round trip miles: ${parseFloat(Round_trip_miles).toFixed(1)}
 ${(commonValues.unpacking === 'Separate Day') ? `Unpacking day Round trip miles: ${parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}` : ''}`
         : `Total miles: ${parseFloat(Combined_mileage).toFixed(1)}`}
 ${(commonValues.extraStops.filter((extraStop, index) =>
           extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
         ).length > 0) ? `There will be ${commonValues.extraStops.filter((extraStop, index) =>
           extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
         ).length} additional stops` : ''}
 DAY 1 - PACKING
 Movers: ${Movers_To_Pack_Day1}
 ${(!isCuFtDepartment) ? `Rate:${getFormattedRateInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Day1, Rate_card_Day1, Truck_fee_Day1).formattedRate}` : ''}
 ${(isCuFtDepartment && Truck_fee_Day1) ? `Truck fee: $${Truck_fee_Day1}` : ''}
 Trucks: ${Trucks_To_Pack_Day1}${(threeDays150Miles.shuttleOnDayOne) ? "+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${threeDays150Miles.quantityDay1})` : '') : ''}
 ${(threeDays150Miles.cubicFtToMoveDay1) ? `Cubic Ft to move:${threeDays150Miles.cubicFtToMoveDay1 + " cu ft."}` : ''}
 ${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && threeDays150Miles.selectedChargesNamesDay1.length > 0) ? `
 Extra options:` : ''}
 ${chargesText}
 ${(cubicf_Day1 > 0 && !isCuFtDepartment) ? `Double Drive: ${Double_drive}` : ''}
 Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day1, Higher_Number_of_Hours_Day1)}
 ${((commonValues.procedures.length > 0) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && threeDays150Miles.proceduresOnDay1) ? 'Procedures:' : ''}
 ${proceduresText}
 ${(commonValues.packing !== "No" && !isCuFtDepartment) ?
         (commonValues.packingKitOverride > 0) ? `Packing Kit:${'$' + Packing_kit_Show} ${commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]`
           : `Packing Kit:${'$' + Packing_kit_Show} ${(commonValues.packing === "Yes") ? "Full" : commonValues.packing} [${Packing_kit_total_amount_with_taxes} with tax]` : ""}
 ${(commonValues.unpacking === 'Same Day') ? 'UNPACKING' : ''}
 ${(isCuFtDepartment && Packing_Charge) ? `Packing Charge:$${Packing_Charge}` : ''}
 ${(commonValues.packing !== "No" && (isCuFtDepartment ? department.packing.packingKits.enabled : true)) ?
         `Packing Supplies On The Go:` +
         `${department.packing.supplies.calculationMethod === 'suppliesPrices' ?
           `• Small box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
          • Medium box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
          • Large box: ${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
          • Roll of packing paper: ${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
          • Roll of bubble wrap: ${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}
          `  : ''}
     ${department.packing.supplies.calculationMethod === 'perBox' ?
           `Per 1 packed box price: $${department.packing.supplies.perOnePackedBox}`
           : ''}`
         : ''
       }
 ${(isCuFtDepartment) ? notIncludedServicesText : ''}
 
 DAY 2 - MOVING
 ${(Number(threeDays150Miles?.moversToLoad) > 0)
         ? `Movers to Load: ${Number(threeDays150Miles.moversToLoad)}
         ${(!isCuFtDepartment) ? `Rate:${getFormattedRateLoadInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Movers_To_Load_Day2, Rate_card_Movers_To_Load_Day2, Truck_fee_Day2).formattedRate.toString()}` : ''}`
         : `Movers: ${Movers_Day2}
 ${(!isCuFtDepartment) ? `Rate:${getFormattedRateInfoTwoDaysDay2(department, Total_Destinationfee_Day2, Rate_cash_Day2, Rate_card_Day2, Truck_fee_Day2).formattedRateDay2}` : ''}
 ${(isCuFtDepartment && Truck_fee_Day2) ? `Truck fee: $${Truck_fee_Day2}` : ''}`}
 ${(Number(threeDays150Miles.moversToDrivesAndUnload) > 0) ?
         `Movers to Unload:${Number(threeDays150Miles.moversToDrivesAndUnload)}
   ${(!isCuFtDepartment) ? `Rate:${(department?.rates && department?.rates.calcMethod === 'deduct')
           ? '$' + Rate_cash_Movers_To_Unload_Day2 + '/h/$' + Rate_card_Movers_To_Unload_Day2 + "/h"
           : '$' + Rate_card_Movers_To_Unload_Day2 + "/h"
           }` : ''}        
         `
 
 
         : ""
       }
 
 
 Trucks: ${Trucks_Day2}${commonValues.shuttle ? " + SHUTTLE" + ((department.extraOptions.shuttle.isCountable) ? `(x${threeDays150Miles.quantityDay2})` : '') : ""}
 
 ${TWO_Trips_Or_OK_Day2 !== "2 TRIPS" && BIGGEST_or_OK_Day2 !== 'OK' ? BIGGEST_or_OK_Day2 : ""}
 
 ${TWO_Trips_Or_OK_Day2 !== 'OK' ? TWO_Trips_Or_OK_Day2 : ""}
 
 ${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && threeDays150Miles.selectedChargesNamesDay2.length > 0) ? `
 Extra options:` : ''}
 ${chargesTextDay2}
 ${additionalServicesText}
 ${(!isCuFtDepartment) ? `Double Drive: ${Double_drive}` : ''}
 ${Total_Destinationfee_Day2 > 0
         ? `Destination fee: $${(department.truckFee.tfdfCombined
           ? Total_Destinationfee_Day2 + Truck_fee_Day1 + Truck_fee_Day2
           : Total_Destinationfee_Day2
         )}`
         : ""
       }
 Total time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day2, Higher_Number_of_Hours_Day2)}
 ${Heavy_fee > 0
         ? `Extra Heavy Items Charge: $${Heavy_fee}`
         : ""
       }
 ${((commonValues.procedures.length > 0) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && threeDays150Miles.proceduresOnDay2) ? 'Procedures:' : ''}
 ${proceduresTextDay2}
 ${commonValues.liftGate ? ' | LIFTGATE' : ''}
 ${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}
 ${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}
 ${selectedMiscCheckMarks.map(checkMark => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks).toUpperCase()}`).join('')}
 
 ${(threeDays150Miles.additionalAmount) ? `Additional amount:${threeDays150Miles.additionalAmount}` : ''}
 
 ${(isCuFtDepartment) ? `FLAT RATE: $${(department?.rates && department.rates.calcMethod === 'deduct') ?
         Flat_rate_Cash_2Days_Packing + ' cash / $' + Flat_rate_Card_2Days_Packing + " card"
         : Flat_rate_Card_2Days_Packing}
 ${(department?.rates && department?.rates.calcMethod !== 'deduct') ? `FLAT RATE with ${getCurrentRatePercent(threeDays150Miles, department)}% cash discount: $${Flat_rate_Cash_2Days_Packing}` : ""}` : ''}
 
 ${(!isCuFtDepartment) ? `NOT TO EXCEED PRICE:$${(department?.rates && department?.rates.calcMethod === 'deduct') ? Total_Cash_2Days_Packing + ' cash / $' + Total_Card_2Days_Packing + " card" : Total_Card_2Days_Packing}
   ${(department?.rates && department?.rates.calcMethod !== 'deduct') ? `NOT TO EXCEED PRICE with ${getCurrentRatePercent(threeDays150Miles, department)}% cash discount:${Total_Cash_2Days_Packing}` : ""}` : ''}
 
 ${unpackingText}
 `;
 
 
 
 
     // Create a textarea element to hold the text
     const textArea = document.createElement("textarea");
     textArea.value = invoiceText.replace(/\n{2,}/g, '\n\n') + '\n';;
 
     // Append the textarea to the document
     document.body.appendChild(textArea);
 
     // Select and copy the text
     textArea.select();
     document.execCommand("copy");
 
     // Remove the textarea from the document
     document.body.removeChild(textArea);
 
   } */
  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && threeDays150Miles.firstDayRate
      && threeDays150Miles.secondDayRate && threeDays150Miles.thirdDayRate) {
      setShowGenerateEmail(true)
    } else {
      setShowGenerateEmail(false)
    }
  }

  return ('a'
    /*     <ul className={styles.paramsSection}>
          <button {...getToggleProps()} className={styles.btnSubmit}>
            <i className={isExpanded ? styles.up : styles.down}></i>
          </button>
          <section {...getCollapseProps()}>
    
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Small boxes:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>{Total_small_boxes}</p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Medium boxes:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>{Total_medium_boxes}</p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Fragile boxes:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Total_fragile_boxes}
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Boxes cubic ft:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Total_box_cubes.toFixed(2) + " cu ft."}
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Time to pack for 1 mover:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Time_to_pack).toFixed(2) + " h"}
              </p>
            </li>
            <li className={styles.paramsItem} >
              <p className={styles.paramsName}>Truckloads from Stop 1:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Stop_1_Truckload).toFixed(3)}
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>
                Truckloads from Additional stops:
              </p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Stop_CN_Truckload).toFixed(3)}
              </p>
            </li>
            <h4>DAY - 1 (Packing)</h4>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Loading hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Loading_hours_Day_1).toFixed(3)} {' h'}
              </p>
            </li>
            {(Driving_Time_Day1 > 0) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Drive Time:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {Driving_Time_Day1} {' h'}
                </p>
              </li>
              : ""}
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Unloading Hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Unloading_hours_Day1).toFixed(3)} {' h'}
              </p>
            </li>
    
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Time:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Packing_Time_Day1).toFixed(3)} {' h'}
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Wrapping Time:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Wrapping_time_Day1).toFixed(3)} {' h'}
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total Hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Total_Hours_Day_1 + " hours"}
              </p>
            </li>
            {(!isCuFtDepartment) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total Day 1:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {(department?.rates && department?.rates.calcMethod === 'deduct') ?
                    '$' + Total_Cash_Day1 + ' cash / $' + Total_Card_Day1 + " card"
                    :
                    <>
                      {'$' + Total_Card_Day1}
                    </>}
                </p>
              </li>
              : ''}
    
            <h4>DAY - 2 (Moving)</h4>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Loading hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Loading_hours_Day2).toFixed(3)} {' h'}
              </p>
            </li>
            {(Number(threeDays150Miles?.moversToLoad) > 0) ?
              <div style={{ paddingLeft: '30px' }}>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Adjusted Loading hours:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    {parseFloat(Adjusted_loading_time_Day2).toFixed(3)} {' h'}
                  </p>
                </li>
              </div>
              :
              ""
            }
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Drive Time:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Drivetime_Day2} {' h'}
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Unloading Hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Unloading_hours_Day2).toFixed(3)} {' h'}
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Total Hours:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Total_Hours_Day2 + " hours"}
              </p>
            </li>
            {(!isCuFtDepartment) ?
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total Day 2:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {(department?.rates && department?.rates.calcMethod === 'deduct') ?
                    '$' + Total_Cash_Day2 + ' cash / $' + Total_Card_Day2 + " card"
                    :
                    <>
                      {'$' + Total_Card_Day2}
                    </>}
                </p>
              </li>
              : ''}
          </section>
          {(department && Object.keys(department).length > 0 && threeDays150Miles.firstDayRate
            && threeDays150Miles.secondDayRate && threeDays150Miles.thirdDayRate && Trucks_To_Pack_Day1 > 0 && Trucks_Day2 > 0
            && Movers_To_Pack_Day1 > 0 && Movers_Day2 > 0) ?
            <div>
              <h2 className={styles.titleCenter}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div>{(Total_box_cubes > 0 && commonValues.packing === 'No') ?
                <>
                  <span style={{ color: 'red' }}> - No Packing
                  </span>
                  <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
                    Confirm
                    <input
                      className={styles.mycheckbox}
                      type='checkbox'
                      name={'isNoPackingConfirmed'}
                      checked={commonValues.isNoPackingConfirmed}
                      value={commonValues.isNoPackingConfirmed}
                      onChange={checkboxValueChange}
                    />
                  </label>
                </> : ''}</h2>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total Cubic ft:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Total_cubes.toFixed(2)}</span> cu ft.
                </p>
              </li>
              {(Total_cubes_Within_Building > 0) ? <>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Cubic ft within building:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Total_cubes_Within_Building).toFixed(2)}</span> cu ft.
                  </p>
                </li>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Cubic ft to move:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)}</span> cu ft.
                  </p>
                </li>
              </> : ''}
              <TruckloadsView
                Truckloads={Truckloads}
                Truckloads_to_move={Truckloads_to_move}
                Total_cubes_Within_Building={Total_cubes_Within_Building}
                scrollRefs={scrollRefs}
                highLightItem={highLightItem}
              />
    
              {(isCuFtDepartment) ?
                <>
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Day 1 - Round trip miles:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(commonValues.milesFromParkingCuFt * 2).toFixed(1)}</span></p>
                  </li>
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Total round trip miles:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Round_trip_miles).toFixed(1)}</span></p>
                  </li>
                  {commonValues.unpacking === 'Separate Day' &&
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>Unpacking day Round trip miles:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(commonValues.milesToParkingCuFt * 2).toFixed(1)}</span></p>
                    </li>
                  }
                </>
                :
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Total miles:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{parseFloat(Combined_mileage).toFixed(1)}</span></p>
                </li>
              }
              {
                (commonValues.extraStops.filter((extraStop, index) =>
                  extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                ).length > 0) ?
                  <li className={styles.paramsItem}><div>
                    There will be <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{commonValues.extraStops.filter((extraStop, index) =>
                      extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                    ).length}</span> additional stops</div>
                  </li>
                  : ''
              }
              <h2 className={styles.titleCenter}>Day 1 - Packing</h2>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Movers:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}><span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Movers_To_Pack_Day1}</span></p>
              </li>
    
              {!isCuFtDepartment &&
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Rate:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    {getFormattedRateInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Day1, Rate_card_Day1, Truck_fee_Day1, highLightItem, scrollRefs, styles).formattedRate}
                  </p>
                </li>
              }
    
              {(isCuFtDepartment && Truck_fee_Day1) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Truck fee:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Truck_fee_Day1}$</span>
                  </p>
                </li>
                : ''
              }
    
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Trucks:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Trucks_To_Pack_Day1}</span>
                  {threeDays150Miles.shuttleOnDayOne && <>  <span className={`${(highLightItem === 'shuttleDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleDay1']}>SHUTTLE</span> ${((department.extraOptions?.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQtyDay1') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQtyDay1']}>(x${threeDays150Miles.quantityDay1})</span> : '')}</>}
                </p>
              </li>
    
              {(threeDays150Miles.cubicFtToMoveDay1) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Cubic Ft to move:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {threeDays150Miles.cubicFtToMoveDay1 + " cu ft."}
                    </span>
                  </p>
    
                </li>
                : ''}
    
              <ExtraOptionsResults
                department={department}
                selectedChargesNames={threeDays150Miles.selectedChargesNamesDay1}
                trucksCount={Trucks_To_Pack_Day1}
                scrollRefs={scrollRefs}
                highLightItem={highLightItem}
              />
    
    
              {(cubicf_Day1 > 0 && !isCuFtDepartment) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Double Drive:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {Double_drive}
                    </span>
                  </p>
                </li>
                : ""}
    
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total Time Range:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                    {getLowerNumberOfHours((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day1)}
                  </span>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                    {getHigherNumberOfHours((department) ? department.minimumHours : 0, Higher_Number_of_Hours_Day1)}
                  </span>
                </p>
              </li>
    
              {((commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && threeDays150Miles.proceduresOnDay1) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Procedures:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
    
                  </p>
                  <ul>
                    {
                      commonValues.procedures.map((procedure, pkIndex) => {
                        if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                          return <li key={pkIndex}><span className={`${(highLightItem === `pIdName_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdName_${procedure.uid}`]}>{procedure.procedureType}</span> x <span className={`${(highLightItem === `pIdQty_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current['destinationFee']}>{procedure.numberOfProcedures}</span>:{
                            (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                              if (procedure.procedureType === crating[`crateName${index}`]) {
                                return <span className={`${(highLightItem === `pIdCost_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdCost_${procedure.uid}`]}> ${(Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]))}</span>;
                              }
                              else {
                                return ''
                              }
                            })
                          }</li>
                        }
                        else { return '' }
                      })
                    }
                  </ul>
                </li>
                : ''}
    
              {(commonValues.packing !== "No" && !isCuFtDepartment) ?
                (commonValues.packingKitOverride > 0) ?
                  <li className={styles.paramsItem} style={{ color: "red" }}>
                    <p className={styles.paramsName}>Packing Kit:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show} {commonValues.packing}</span>  <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                    </p>
                  </li>
                  :
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Packing Kit:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === 'packingKit') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKit']}>{'$' + Packing_kit_Show}</span>  {(commonValues.packing === "Yes") ? "Full" : commonValues.packing} <span className={`${(highLightItem === 'packingKitWithTax') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingKitWithTax']}>[${Packing_kit_total_amount_with_taxes}</span> with tax]
                    </p>
                  </li>
                : ""}
    
              {(commonValues.unpacking === 'Same Day') &&
                <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
                  <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>UNPACKING</span>  <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>{commonValues.partialUnPacking && "PARTIAL"}</span></span>
                </div>
              }
    
              {(isCuFtDepartment && Packing_Charge) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Packing Charge:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === 'packingCharge') ? styles.highLighted : ""}`} ref={scrollRefs?.current['packingCharge']}>${Packing_Charge}</span>
                  </p>
                </li>
                : ''
              }
                  
              {(isCuFtDepartment) ? <AdditionalServicesEstimateInfo
                commonValues={commonValues}
                department={department}
                totalCubes={Total_cubes}
                scrollRefs={scrollRefs}
                highLightItem={highLightItem} /> : ''}
    
              <h2 className={styles.titleCenter}>Day 2 - Moving</h2>
    
              {(Number(threeDays150Miles?.moversToLoad) > 0)
                ?
                <>
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Movers to Load:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                        {Number(threeDays150Miles.moversToLoad)}
                      </span>
                    </p>
                  </li>
                  {!isCuFtDepartment &&
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>Rate:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}>
                        {getFormattedRateLoadInfoTwoDays(department, Total_Destinationfee_Day2, Rate_cash_Movers_To_Load_Day2, Rate_card_Movers_To_Load_Day2, Truck_fee_Day2).formattedRate}
                      </p>
                    </li>
                  }
                </>
                :
                <>
                  <li className={styles.paramsItem}>
                    <p className={styles.paramsName}>Movers:</p>
                    <span className={styles.paramsDecor}></span>
                    <p className={styles.paramsValue}>
                      <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                        {Movers_Day2}
                      </span>
                    </p>
                  </li>
                  {!isCuFtDepartment &&
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>Rate:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}>
                        {getFormattedRateInfoTwoDaysDay2(department, Total_Destinationfee_Day2, Rate_cash_Day2, Rate_card_Day2, Truck_fee_Day2, highLightItem, scrollRefs, styles).formattedRateDay2}
                      </p>
                    </li>
                  }
                  {(isCuFtDepartment && Truck_fee_Day2) ?
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>Truck fee:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}>
                        <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                          {Truck_fee_Day2}$
                        </span>
                      </p>
                    </li>
                    : ''
                  }
                </>
              }
    
              {
                (Number(threeDays150Miles.moversToDrivesAndUnload) > 0)
                  ?
                  <>
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>Movers to Unload:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}>
                        <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                          {Number(threeDays150Miles.moversToDrivesAndUnload)}
                        </span>
                      </p>
                    </li>
                    {!isCuFtDepartment &&
                      <li className={styles.paramsItem}>
                        <p className={styles.paramsName}>Rate:</p>
                        <span className={styles.paramsDecor}></span>
                        <p className={styles.paramsValue}>   {(department?.rates && department?.rates.calcMethod === 'deduct')
                          ? <> <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_cash_Movers_To_Unload_Day2}/h</span>/  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_card_Movers_To_Unload_Day2}/h</span></>
                          : <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Rate_card_Movers_To_Unload_Day2}/h</span>
                        }</p>
                      </li>
                    }
                  </>
                  : ""
              }
    
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Trucks:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Trucks_Day2}</span>
                  {commonValues?.shuttle && <>  <span className={`${(highLightItem === 'shuttle') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttle']}>SHUTTLE</span> ${((department.extraOptions?.shuttle.isCountable) ? <span className={`${(highLightItem === 'shuttleQty') ? styles.highLighted : ""}`} ref={scrollRefs?.current['shuttleQty']}>(x${threeDays150Miles.quantityDay2})</span> : '')}</>}
                </p>
              </li>
    
              {(TWO_Trips_Or_OK_Day2 !== "2 TRIPS") ?
                (BIGGEST_or_OK_Day2 !== 'OK') ?
                  <li className={styles.paramsItem}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {BIGGEST_or_OK_Day2}
                    </span>
                  </li>
                  : ""
                : ''}
              {(TWO_Trips_Or_OK_Day2 !== 'OK' && !(commonValues.baseLoadingHours == 0 || commonValues.baseUnloadingHours == 0)) ?
                <li className={styles.paramsItem}>
                  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                    {TWO_Trips_Or_OK_Day2}
                  </span>
                </li>
                : ""
              }
    
              <ExtraOptionsResults
                department={department}
                selectedChargesNames={threeDays150Miles.selectedChargesNamesDay2}
                trucksCount={Trucks_Day2}
                scrollRefs={scrollRefs}
                highLightItem={highLightItem}
              />
    
              {isCuFtDepartment &&
                <AdditionalServicesResults
                  selectedAdditionalServices={commonValues.selectedAdditionalServices}
                  department={department}
                  additionalServicesPlaceType={commonValues.additionalServicesPlaceType}
                  Total_cubes={Total_cubes}
                  trucksCount={Trucks_Day2}
                  scrollRefs={scrollRefs}
                  highLightItem={highLightItem}
                />
              }
    
              {!isCuFtDepartment &&
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Double Drive:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      {Double_drive}
                    </span>
                  </p>
                </li>}
    
              {(Total_Destinationfee_Day2 > 0) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Destination fee:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>$
                    {(department.truckFee.tfdfCombined) ?
                      <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Total_Destinationfee_Day2 + Truck_fee_Day1 + Truck_fee_Day2}</span>
                      : <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Total_Destinationfee_Day2}</span>
                    }
                  </p>
                </li>
                : ""}
    
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Total time Range:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {getTotalTimeRange((department) ? department.minimumHours : 0, Lower_Number_of_Hours_Day2, Higher_Number_of_Hours_Day2)}</p>
              </li>
    
              {(Heavy_fee > 0) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Extra Heavy Items Charge:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      ${Heavy_fee}
                    </span>
                  </p>
                </li>
                : ""}
    
              {((commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) && (commonValues.procedures[0].procedureType !== "None" && commonValues.procedures[0].procedureType !== "") && threeDays150Miles.proceduresOnDay2) ?
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Procedures:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
    
                  </p>
                  <ul>
                    {
                      commonValues.procedures.map((procedure, pkIndex) => {
                        if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                          return <li key={pkIndex}><span className={`${(highLightItem === `pIdName_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdName_${procedure.uid}`]}>{procedure.procedureType}</span> x <span className={`${(highLightItem === `pIdQty_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current['destinationFee']}>{procedure.numberOfProcedures}</span>:{
                            (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                              if (procedure.procedureType === crating[`crateName${index}`]) {
                                return <span className={`${(highLightItem === `pIdCost_${procedure.uid}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`pIdCost_${procedure.uid}`]}> ${(Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]))}</span>;
                              }
                              else {
                                return ''
                              }
                            })
                          }</li>
                        }
                        else { return '' }
                      })
                    }
                  </ul>
                </li>
                : ''}
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'liftGate') ? styles.highLighted : ""}`} ref={scrollRefs?.current['liftGate']}>{commonValues.liftGate && ' | LIFTGATE'}</span></span>
                <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'hardwoodFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['hardwoodFloorCovers']}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span></span>
                <span style={{ whiteSpace: 'nowrap' }}> <span className={`${(highLightItem === 'carpetFloorCovers') ? styles.highLighted : ""}`} ref={scrollRefs?.current['carpetFloorCovers']}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span></span>
                {selectedMiscCheckMarks.map((checkMark, chmpkIndex) => <span key={chmpkIndex} style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
                  {' '} |  <span className={`${(highLightItem === `mChId_${checkMark}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`mChId_${checkMark.uId}`]}>{getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}</span>
                </span>)}
              </div>
    
              {(threeDays150Miles.additionalAmount) ? (
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>Additional amount:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                      ${threeDays150Miles.additionalAmount}
                    </span>
                  </p>
                </li>) : ''
              }
    
              {isCuFtDepartment && <>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>FLAT RATE:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>$
                    {(department?.rates && department.rates.calcMethod === 'deduct') ?
                      <> <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{Flat_rate_Cash_2Days_Packing}</span> cash /  <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Flat_rate_Card_2Days_Packing}</span> card</>
                      :
                      <>
                        <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                          {Flat_rate_Card_2Days_Packing}
                        </span>
                      </>}
                  </p>
                </li>
                {(department?.rates && department?.rates.calcMethod !== 'deduct') ?
                  <div style={{ paddingLeft: '30px' }}>
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>FLAT RATE with {getCurrentRatePercent(threeDays150Miles, department)}% cash discount:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}>
                        <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                          ${Flat_rate_Cash_2Days_Packing}
                        </span>
                      </p>
                    </li>
                  </div>
                  : ""}
              </>}
    
              {(!isCuFtDepartment) ? <>
                <li className={styles.paramsItem}>
                  <p className={styles.paramsName}>NOT TO EXCEED PRICE:</p>
                  <span className={styles.paramsDecor}></span>
                  <p className={styles.paramsValue}>
                    {(department?.rates && department?.rates.calcMethod === 'deduct') ? <>
                      <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Total_Cash_2Days_Packing}</span> cash /   <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Total_Card_2Days_Packing}</span> card
                    </>
                      :
                      <>
                        <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>
                          ${Total_Card_2Days_Packing}
                        </span>
                      </>}
                  </p>
                </li>
    
                {(department?.rates && department?.rates.calcMethod !== 'deduct') ?
                  <div style={{ paddingLeft: '30px' }}>
                    <li className={styles.paramsItem}>
                      <p className={styles.paramsName}>NOT TO EXCEED PRICE with   <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>{getCurrentRatePercent(threeDays150Miles, department)}%</span> cash discount:</p>
                      <span className={styles.paramsDecor}></span>
                      <p className={styles.paramsValue}>
                        <span className={`${(highLightItem === '') ? styles.highLighted : ""}`} ref={scrollRefs?.current['']}>${Total_Cash_2Days_Packing}</span>
                      </p>
                    </li>
                  </div>
                  : ""}
              </> : ''}
    
              <CalculationSeparateDayView commonValues={commonValues} anyCalculationType={threeDays150Miles} department={department}
                Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
                Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
                Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
                Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
                Total_Card_Unpacking_Separate_Day={Total_Card_Unpacking_Separate_Day}
                Total_Cash_Unpacking_Separate_Day={Total_Cash_Unpacking_Separate_Day}
                Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
                Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
                Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
                Flat_rate_Unpacking_Separate_Day={Flat_rate_Unpacking_Separate_Day}
                Flat_rate_Unpacking_Separate_Day_cash={Flat_rate_Unpacking_Separate_Day_cash}
                isCuFtDepartment={isCuFtDepartment}
                UnPacking_Charge={UnPacking_Charge}
                highLightItem={highLightItem}
                scrollRefs={scrollRefs}
              />
            </div>
            : ""}
        </ul> */
  );
};
